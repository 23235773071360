@import "../../variable.scss";
.projects-wrapper {
    padding: 20px;
    background-color: #f9f9f9;

    .container {
        margin-bottom: 20px;

        .projects-title {
            font-size: 2em;
            text-align: center;
            margin-bottom: 20px;
        }
    }

    .container-projects {
        margin-bottom: 40px;

        h2 {
            font-size: 1.5em;
            margin-bottom: 20px;
            text-align: center;
        }

        .detailed-section{
            display: flex;
            flex-direction: column;
            align-items: stretch;
            
            > div {
                background-color: $light-color;
                box-shadow: 0 0 8px rgba(0,0,0,0.1);
                border-radius: 4px;
                margin: 2rem 0;
                flex-grow: 1;
                height: 75vh;
                padding: 2rem;
                overflow-y: auto;
                width: 95%;
                //center the content
                align-self: center;
            }
        }

        .card-section {
            padding: 20px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between; // Adjust if you prefer a different alignment
            gap: 30px; // Adds space between the cards

            > * { // Direct children of the sections
                flex-basis: calc(33.333% - 20px); // For 3 per row, adjusting for gap. Change as needed.
                margin-bottom: 20px;
            }
        }
    }
}

// Responsive adjustments
@media screen and (max-width: 768px) {
    .projects-wrapper {
        .container-projects {
            .detailed-section, .card-section {
                justify-content: space-around;

                > * {
                    flex-basis: calc(50% - 20px); // Adjust if you want narrower cards on mobile
                }
            }
        }
    }
}
