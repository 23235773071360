@import "../../variable.scss";


.project-section {
    padding: 20px;
    background-color: #ffffff;
    margin: 20px 0;
    border-radius: 10px;
    // min-height: 10px;
    min-height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .container {
        max-width: 1440px;
        height: auto;
        margin: 10px;
        .row {
            min-height: 100%;
            // height: 100%;
            .project-description {
                text-align: left;
                .project-name {
                    font-size: 24px;
                    margin-bottom: 5px;
                }
                .project-type {
                    font-size: 16px;
                    color: red;
                    margin-bottom: 10px;
                }
                hr {
                    border-top: 1px solid #ccc;
                    margin-bottom: 20px;
                    width: 100%;
                }
                p {
                    margin-bottom: 10px;
                }
                .btn {
                    display: inline-block;
                    margin-top: 10px;
                }
            }
            .project-image {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    max-height: 100%;
                    width: auto;
                    border-bottom: 2px solid #f0f0f0;
                    border-radius: 8px;
                    object-fit: contain;
                }
            }
            
        }
    }
    @media (max-width: 767px) {
        min-height: auto;
        .container {
            margin: 20px;
            .row {
                // flex-direction: column-reverse;
                .project-description{
                    text-align: left;
                    padding: 12px;
                }
                
                .project-image {
                    text-align: center;
                    padding: 12px;
                }
                .project-image img {
                    max-width: 100%;
                    border-bottom: none;
                }
            }
        }
    }
}
