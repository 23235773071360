// Importing variables (assuming you have defined styles like colors, fonts, etc.)
@import "../../variable.scss";

.project-card {
    perspective: 1000px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    margin: 0;
    width: 100%;
    overflow: hidden;

    .card-inner {
        transition: transform 0.6s;
        transform-style: preserve-3d;
        position: relative;
        width: 100%;
        height: 200px;
    }

    .card-front, .card-back {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        backface-visibility: hidden;
    }

    .card-front {
        .project-image-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            .project-image {
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
            }
        }
    }

    .card-back {
        transform: rotateY(180deg);
        background-color: #f9f9f9;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px;

        .tech-title {
            font-size: 0.9em;  // Adjusts the font size; you can set this to any value that looks good
            margin-bottom: 10px;  // Optional: Adds some space below the title
        }
    
        .tech-list {
            padding: 0 15px;  // Adds padding to the left and right sides; adjust as needed
        }
    
        .btn {
            margin: 10px 15px 0 15px;  // Adds margin to the top, left, and right sides; adjust as needed
        }
    }

    &:hover .card-inner {
        transform: rotateY(180deg);
    }

    // CONTENT STYLES
    .project-content {
        margin-top: 10px;

        .project-title {
            margin: 0 0 10px 0;
            font-size: 1.25em;
        }

        .project-link {
            color: blue; // Assuming you have color variables in your variable.scss
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    // HOVER EFFECTS
    &:hover {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    }
}
.project-title-front {
    text-align: center;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);  // Optional: Adds a semi-transparent background
    color: #fff;  // Sets the text color to white
    position: absolute;
    width: 100%;
    bottom: 0;
}

// RESPONSIVE STYLES
@media screen and (max-width: 768px) {
    .project-card {
        width: 100%; // Makes it responsive, taking full width on smaller screens
        height: auto;
    }
}
